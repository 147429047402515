import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { apollo } from "../../../../utils/apollo"; // Assuming apollo is set up for making API calls
import TextInput from "../../../../components/TextInput";
import { toast } from "react-toastify";
import { GET_SHAFAFIYA_HISTORY } from "../../../../utils/graphql";

// Function to fetch the Shafafiya history
const fetchShafafiyaHistory = async (emiratesId) => {
    const response = await apollo.query({
        query: GET_SHAFAFIYA_HISTORY, // Replace this with your actual query
        variables: { eid: emiratesId }
    });
    return response.data.getShafafiyaHistory; // This will return an array of histories
};

function COCHistoryView() {
    const [searchTerm, setSearchTerm] = useState("");
    const [historyData, setHistoryData] = useState(null);

    // useQuery to fetch data when Emirates ID is entered
    const { data, isLoading, error, refetch } = useQuery(
        ['shafafiyaHistory', searchTerm],
        () => fetchShafafiyaHistory(searchTerm),
        {
            enabled: false, // Don't automatically fetch; wait for button click
            onSuccess: (data) => {
                if (data && data.length > 0) {
                    setHistoryData(data); // Directly set the array of histories to the state
                } else {
                    setHistoryData([]); // Handle case when no history is found
                    toast.error('No data found for the provided Emirates ID');
                }
            },
            onError: () => {
                setHistoryData([]); // Clear data in case of an error
                toast.error('Error fetching data');
            }
        }
    );

    // Handle search button click
    const handleSearchClick = () => {
        if (searchTerm.length > 0) {
            // Trigger the query manually
            refetch(); // Refetches the query with the current searchTerm
        } else {
            toast.error('Please enter an Emirates ID to search');
        }
    };

    return (
        <div className="mt-6">
            <div className="mt-4 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle">
                        <div className="my-4 shadow-md bg-white border rounded-lg px-4 py-4 grid grid-cols-1 md:grid-cols-12 gap-4">
                            <div className="col-span-3">
                                <TextInput
                                    name="search-term"
                                    label="Search Shafafiya History by Emirates Id"
                                    id="search-term"
                                    get={searchTerm}
                                    set={setSearchTerm}
                                />
                            </div>
                            <div className="col-span-3 flex mt-7 justify-left">
                                <button
                                    onClick={handleSearchClick}
                                    className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600"
                                >
                                    Search
                                </button>
                            </div>
                        </div>

                        {/* Display loading state */}
                        {isLoading ? (
                            <div className="text-center">Loading...</div>
                        ) : (
                            // Show results if data exists
                            historyData && historyData.length > 0 ? (
                                <div className="mt-4">
                                    <table className="min-w-full table-auto">
                                        <thead>
                                            <tr>
                                                <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">First Name</th>
                                                <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Middle Name</th>
                                                <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Last Name</th>
                                                <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Birthdate</th>
                                                <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Policy Start Date</th>
                                                <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Policy Renewal Date</th>
                                                <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Policy Expiry Date</th>
                                                <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Insurance License</th>
                                                <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
                                                <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Relation</th>
                                                <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Relation To</th>
                                                <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Unified Number</th>
                                                <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Emirates ID</th>
                                                <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Transaction Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {historyData.map((history, index) => (
                                                <tr key={index}>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{history.firstName}</td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{history.middleName}</td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{history.lastName}</td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{history.birthdate}</td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{history.policyStartDate}</td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{history.policyRenewalDate}</td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{history.policyExpiryDate}</td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{history.insuranceLicense}</td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{history.status}</td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{history.relation}</td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{history.relationTo}</td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{history.unifiedNumber}</td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{history.emiratesId}</td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{history.transactionDate}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            ) : (
                                <div className="text-center">No data found for the given Emirates ID</div>
                            )
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default COCHistoryView;
