import React, {useMemo, useState} from 'react';
import DashboardLayout from "../../../layouts/DashboardLayout";
import PageHeader from "../../../components/PageHeader";
import {useQuery} from "react-query";
import {apollo} from "../../../utils/apollo";
import {GET_FILE_IMPORT_STATUS_COUNT, GET_INTEGRATION_LIST} from "../../../utils/graphql";
import usePolicyStore from "../../../store/policy";
import COOPortalPolicyImportForm from "./forms/coc-portal-import-form";
import {FILE_IMPORT_STATUS} from "../../../utils/constants";
import Tabs from "../../../components/Tabs";
import {CheckIcon, ClockIcon, QueueListIcon, XMarkIcon} from "@heroicons/react/24/outline";
import {cn} from "../../../utils/style";
import Loading from "../../../components/Loading";
import SelectInput from "../../../components/SelectInput";
import PolicyImportView from "./views/policy-import-view";
import COCImportView from "./views/coc-import-view";
import COCHistoryView from "./views/coc-history-view"


function CocPortal() {
    

    const {integrationUuid, setIntegrationUuid} = usePolicyStore();
    // State for FILE_UUID initialized to null
    const [fileUuid, setFileUuid] = useState(null);

    const [activeTab, setActiveTab] = useState('overview');
    const [statusTabs, setStatusTabs] = useState(FILE_IMPORT_STATUS)

    const fetchIntegrationList = useQuery({
        queryKey: 'integration_list', queryFn: async () => {
            const {data} = await apollo.query({
                query: GET_INTEGRATION_LIST, variables: {},
            });
            return data?.getIntegrationList;
        },
        keepPreviousData: true,
        onSuccess: ({data}) => {
            if (data?.length >= 1) {
                // auto select the first item if only 1 is available
                data.forEach((item) => {
                    if (item?.integrationType && (item?.integrationType === 'Shafafiya' || item?.integrationType === 'shafafiya')) {
                        setIntegrationUuid(item?.integrationUuid);
                    }
                });
            }
        }
    })

    useQuery('file_import_status_count', async () => {
        const {data} = await apollo.query({
            query: GET_FILE_IMPORT_STATUS_COUNT, variables: {},
        });
        return data?.getFileImportStatusCount;
    }, {
        keepPreviousData: false,
        refetchInterval: 5000,
        cacheTime: 5000,
        staleTime: 5000,
        onSettled: (data) => {
            if (data && Array.isArray(data)) {
                let updatedStatusTabs = [...statusTabs];
                data.forEach((item) => {
                    let foundIndex = updatedStatusTabs.findIndex(tab => tab.column === item.status);
                    if (foundIndex !== -1) {
                        updatedStatusTabs[foundIndex].count = parseInt(item.count);
                    }
                });
                setStatusTabs(updatedStatusTabs);
            }
        },
    });

    const integrationList = useMemo(() => fetchIntegrationList?.isSuccess ? fetchIntegrationList?.data?.data : null, [fetchIntegrationList]);
    const integration = useMemo(() => {
        const l = integrationList?.filter(i => i.integrationUuid === integrationUuid);
        return l && l.length > 0 ? l[0] : null;
    }, [integrationUuid, integrationList]);


    return (<DashboardLayout>
        <PageHeader title="Member Bulk Upload" description="Import policies from external sources.">
            <Tabs hideOnMobile={false} tabs={statusTabs} rightSide={true} get={activeTab} set={v => setActiveTab(v)}/>
        </PageHeader>
        {activeTab === 'overview' && <div>
            <div className="mt-6">
            {integration?.integrationType !== 'slashdata' &&
                <COOPortalPolicyImportForm 
                            integration={integration} 
                            fileUuid={fileUuid} 
                            setFileUuid={setFileUuid} 
                        />}
            </div>
            <COCHistoryView/>
            <COCImportView fileUuid={fileUuid}/>
        </div>}

        
    </DashboardLayout>);
}

function FileImportCardsOverview({statusTabs}) {
    // todo: get stats based on day, week, month
    let status = useMemo(() => {
        let list = {
            overview: 0,
           
        };
        statusTabs?.forEach(s => {
            list[s.column] = s?.count
        });
        return list
    }, [statusTabs]);

    return <div className="mt-12">
        <h2 className="font-semibold mb-4">Overview for uploaded files</h2>
        
    </div>;
}

function OverviewLine({icon, title, count, color}) {

    const colors = {
        red: {
            border: 'border-4 border-double border-red-300',
            icon: 'text-red-500',
            text: 'text-red-800',
            bg: 'bg-red-50/50',
        },
        blue: {
            border: 'border-4 border-double border-blue-300',
            icon: 'text-blue-500',
            text: 'text-blue-800',
            bg: 'bg-blue-50/50',
        },
        orange: {
            border: 'border-4 border-double border-orange-300',
            icon: 'text-orange-500',
            text: 'text-orange-800',
            bg: 'bg-orange-50/50',
        },
        green: {
            border: 'border-4 border-double border-green-300',
            icon: 'text-green-500',
            text: 'text-green-800',
            bg: 'bg-green-50/50',
        },
        default: {
            border: 'border-4 border-double border-gray-300',
            icon: 'text-gray-500',
            text: 'text-gray-800',
            bg: 'bg-gray-50/50',
        }
    };
    const colorScheme = colors[color] || colors.default;

    return <div className={cn(
        `flex shrink-0 flex-col justify-center items-center bg-gray-100 rounded-lg px-4 py-8`,
        colorScheme.border, colorScheme.bg
    )}>
        <div className={cn(`mb-3`, colorScheme.icon)}>{icon}</div>
        
    </div>;
}

export default CocPortal;
