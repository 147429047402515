import React, {useMemo, useState} from 'react';
import {useQuery, useQueryClient, useMutation} from "react-query";
import {apollo} from "../../../../utils/apollo";
import {GET_COC_IMPORT_LIST} from "../../../../utils/graphql";
import {GENERATE_COC_CSV} from "../../../../utils/graphql";
import PaginationFull from "../../../../components/PaginationFull";
import TextInput from "../../../../components/TextInput";
import {Menu, MenuButton, MenuItem, MenuItems} from '@headlessui/react'
import {EllipsisVerticalIcon} from '@heroicons/react/20/solid'
import {RECHECK_COC_STATUS} from "../../../../utils/graphql";
import {toast} from "react-toastify";

function COCImportView({fileUuid}) {    
    
    const [page, setPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState(""); 

    const fetchFileImportProcessing = useQuery({
        queryKey: ['coc_import_processing_view_list', { page, fileUuid, searchTerm }],
        queryFn: async () => {
            const { data } = await apollo.query({
                query: GET_COC_IMPORT_LIST,
                variables: {
                    page,
                    first: 15,
                    filter: {
                        fileUuid: fileUuid || null,
                        searchTerm: searchTerm || null, // Use searchTerm filter
                    },
                    fetchAll: false // Full data for export
                },
            });
            return data?.getCOCImportList;
        },
        keepPreviousData: false,
    });

    const fileImports = useMemo(() => fetchFileImportProcessing?.isSuccess ? fetchFileImportProcessing?.data : null, [fetchFileImportProcessing]);

    const generateCocCsvMutation = useMutation({
        mutationFn: async () => {
          const { data } = await apollo.mutate({
            mutation: GENERATE_COC_CSV,
          });
          return data?.generateCocCsv;
        },
        onError: (error) => {
          toast.error("An error occurred while generating the CSV.");
          console.error(error);
        },
        onSuccess: (data) => {
          if (data?.success) {
            toast.success("CSV generated successfully.");
            const csvFileUrl = data?.csvFile;
      
            // Create a hidden <a> element to trigger the download
            const a = document.createElement("a");
            a.href = csvFileUrl; // The URL to the generated CSV
            a.download = "coc_export.csv"; // Name of the file to download
            document.body.appendChild(a); // Append the link to the body
            a.click(); // Trigger a click event on the link to download the file
            document.body.removeChild(a); // Remove the link after the download
          } else {
            toast.error(data?.message || "Failed to generate CSV.");
          }
        }
      });
      
      // Function to export data to CSV using the mutation
      const exportToCSV = async () => {
        try {
          // Trigger the mutation to generate the CSV
          await generateCocCsvMutation.mutateAsync();
        } catch (error) {
          console.error("Error exporting CSV:", error);
        }
      };

    return (<div className="mt-6">
        
        <div className="mt-4 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle">
                    <div className="my-4 shadow-md bg-white border rounded-lg px-4 py-4 grid grid-cols-1 md:grid-cols-12 gap-4">
                        <div className="col-span-3">
                            <TextInput
                                name="search-term"
                                label="Search by Emirates Id, Shafafiya Ref, or UId"
                                id="search-term"
                                get={searchTerm}
                                set={setSearchTerm}
                            />
                        </div>
                        <div className="col-span-3 flex mt-7 justify-end"> {/* Aligned the button to the right */}
                            <button
                                onClick={exportToCSV}
                                className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600"
                            >
                                Export to CSV
                            </button>
                        </div>
                    </div>
                    <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                        <tr>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            ShafafiyaReferenceNo
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                EmiratesId
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                UId
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                TotalPenalty
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Penalty
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                NoInsuranceDays
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                ValidityDate
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                ErrorCode
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                
                            </th>
                        </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                        {fileImports?.data?.map((fileImport) => (
                            <tr key={fileImport.shafafiyaReferenceNo}>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {fileImport.shafafiyaReferenceNo}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {fileImport.eid}
                                </td>                                
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {fileImport.uid}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {fileImport.totalPenalty}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {fileImport.penalty}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {fileImport.nonInsuranceDays}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {fileImport.validityDate}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {fileImport.errorCode}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    <Actions record={fileImport}/>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    {fileImports?.data && !fileImports?.data?.length &&
                        <div className="flex items-center justify-center h-40 text-gray-500 font-light text-xl">
                            No file imports found
                        </div>}
                </div>
            </div>
        </div>
        <PaginationFull paginator={fileImports?.paginatorInfo} get={page} set={setPage}/>
    </div>);
}

function Actions({record}) {
    const queryClient = useQueryClient()

    const reCheckCOCStatusMutation = useMutation({
        mutationFn: async () => {
            const {data} = await apollo.mutate({
                mutation: RECHECK_COC_STATUS,
                variables: {
                    eid: record?.eid
                },
            });
            return data?.reCheckCocStatus;
        },
        onError: ((error) => {
            toast.error("Recheck operation failed.")
            console.error(error)
        }),
        onSuccess: async (data) => {
            if (data) {
                toast.success("Recheck operation completed.")
            } else {
                toast.error("Recheck operation failed, please try after 1 minute.")
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries(['coc_import_processing_view_list']);
        }
    })

    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <MenuButton
                    className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                    <span className="sr-only">Open options</span>
                    <EllipsisVerticalIcon aria-hidden="true" className="h-6 w-6"/>
                </MenuButton>
            </div>

            <MenuItems
                transition
                className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
            >
                <div className="py-1">
                    <MenuItem>
                        <span
                            className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                            onClick={() => reCheckCOCStatusMutation.mutate()}>Re-Check</span>
                    </MenuItem>
                </div>
            </MenuItems>
        </Menu>
    )
}

export default COCImportView;
